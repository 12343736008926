import ApiService from '@core/api/services/ApiService';
import { HydrateOption } from '@core/enums';
import UrlResolver from '@core/url-resolver/UrlResolver';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import Button from '@stories/Components/Buttons/Button/Button';
import FormField from '@stories/Components/Forms/FormField/FormField';
import TextArea from '@stories/Components/Forms/TextArea/TextArea';
import Textbox from '@stories/Components/Forms/Textbox/Textbox';
import Heading from '@stories/Components/Global/Typography/Heading';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import Loader from '@stories/Components/Misc/Loader/Loader';
import React, { useEffect, useState } from 'react';
import { ContactUsStyles as S } from './ContactUs.styles';
import { ThemeWrapper } from '@helpers/theme';
const ContactUs = ({ contactOptions, introCopy, title, translations, confirmationTitle, confirmationMessage, siteId, cultureCode, theme, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [success, setSuccess] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const getErrorLabel = (field) => {
        if (!response) {
            return null;
        }
        const error = response.validationErrors?.find((x) => x.errorSourceId === field);
        if (!error) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(S.Error, null, error.message)));
    };
    const submit = async () => {
        setIsLoading(true);
        const params = {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            message,
            siteId,
        };
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            method: 'POST',
            slug: 'submit',
            controller: 'contact',
            params,
        });
        setIsLoading(false);
        if (response.status.success === true) {
            setSuccess(true);
        }
        setResponse(response);
    };
    const handleUnload = async () => {
        const cookieConsent = document.cookie.match(new RegExp(`(^| )CookieScriptConsent=([^;]+)`));
        if (!cookieConsent) {
            return;
        }
        const consentJson = JSON.parse(cookieConsent[2]);
        if (!consentJson.categories.includes('targeting')) {
            return;
        }
        if (firstName === '' ||
            lastName === '' ||
            emailAddress === '' ||
            phoneNumber === '' ||
            message === '') {
            await ApiService.request({
                baseUrl: UrlResolver.getDomain(),
                slug: 'partial-contact-submit',
                controller: 'contact',
                method: 'POST',
                params: {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    phoneNumber: phoneNumber,
                    message: message,
                    siteId,
                    cultureCode,
                    formName: title,
                },
                keepAlive: true,
            });
        }
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload, false);
        return () => window.removeEventListener('beforeunload', handleUnload, false);
    }, [firstName, lastName, emailAddress, phoneNumber, message]);
    return (React.createElement(ThemeWrapper, { "data-theme": theme },
        React.createElement(S.Wrapper, null,
            React.createElement(S.ImageOverlay, null),
            React.createElement(S.ContentWrapper, null,
                React.createElement(S.ContentWrapperInner, null,
                    React.createElement(Grid.Default, null,
                        React.createElement(S.ContentInner, null,
                            React.createElement(S.Title, { semantic: "h1", visual: "h3" }, title),
                            introCopy && React.createElement(S.Intro, { dangerouslySetInnerHTML: { __html: introCopy } }),
                            contactOptions.map((option) => (React.createElement(S.ContactBox, { key: option.id },
                                React.createElement(S.ContactTitle, null, option.location),
                                React.createElement(S.ContactItem, { size: "regular" },
                                    "t: ",
                                    React.createElement("a", { href: `tel:${option.phone}` }, option.phone)),
                                option.email && (React.createElement(S.ContactItem, { size: "regular" },
                                    "e: ",
                                    React.createElement("a", { href: `mailto:${option.email}` }, option.email))))))),
                        React.createElement(S.FormInner, null, isLoading ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null, success ? (React.createElement(S.ConfirmationWrapper, null,
                            React.createElement(Heading, { semantic: "h3", visual: "h1" }, confirmationTitle),
                            React.createElement(Paragraph, { size: "regular" }, confirmationMessage))) : (React.createElement(S.FormGrid, null,
                            React.createElement(S.Field, null,
                                React.createElement(FormField, null,
                                    React.createElement(Textbox, { id: "firstName", type: "text", value: firstName, onChange: (e) => setFirstName(e.currentTarget.value), label: translations['custom.forms.firstname'] })),
                                getErrorLabel('firstName')),
                            React.createElement(S.Field, null,
                                React.createElement(FormField, null,
                                    React.createElement(Textbox, { id: "lastName", type: "text", value: lastName, onChange: (e) => setLastName(e.currentTarget.value), label: translations['custom.forms.lastname'] })),
                                getErrorLabel('lastName')),
                            React.createElement(S.Field, null,
                                React.createElement(FormField, null,
                                    React.createElement(Textbox, { id: "email", type: "email", value: emailAddress, onChange: (e) => setEmailAddress(e.currentTarget.value), label: translations['custom.forms.emailaddress'] })),
                                getErrorLabel('emailAddress')),
                            React.createElement(S.Field, null,
                                React.createElement(FormField, null,
                                    React.createElement(Textbox, { id: "phone", type: "tel", value: phoneNumber, onChange: (e) => setPhoneNumber(e.currentTarget.value), label: translations['custom.forms.phonenumber'] })),
                                getErrorLabel('phoneNumber')),
                            React.createElement(S.Field, { wide: true },
                                React.createElement(FormField, null,
                                    React.createElement(TextArea, { id: "message", label: translations['custom.forms.message'], value: message, onChange: (e) => setMessage(e.currentTarget.value) }),
                                    getErrorLabel('message'))),
                            React.createElement(S.Field, { wide: true, center: true },
                                React.createElement(FormField, null,
                                    React.createElement(Button, { title: translations['custom.forms.submit'], branding: "primaryAlt", onClick: submit }))))))))))))));
};
export default withWidget(ContactUs, 'ContactUs', { hydrate: HydrateOption.InView });
