import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import SvgSelectArrow from '@img/icons/select-arrow-up.svg';
import { ThemeSwitch } from '@helpers/theme';
const Wrapper = styled.div `
  label {
    display: block;
    font-size: 16px;
    ${fonts.DaxPro['Bold']};
    color: ${brand.grey.grey20};
    margin-bottom: 6px;
  }

  ${({ isOpen }) => isOpen &&
    css `
      ${() => Dropdown} {
        opacity: 1;
        pointer-events: all;
      }

      ${() => Element} {
        &:after {
          transform: rotate(0deg);
        }
      }
    `}
`;
const Element = styled.div `
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  border: 1px solid ${rgba(brand.grey.grey55, 0.75)};
  box-sizing: border-box;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  height: 50px;
  padding: 0 36px 0 18px;
  width: auto;
  min-width: 318px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 18px;
    width: 18px;
    height: 100%;
    background: url(${SvgSelectArrow}) no-repeat center;
    background-size: contain;
    transform: rotate(180deg);
    opacity: 0.5;
  }
`;
const Dropdown = styled.div `
  position: absolute;
  z-index: 1;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px 2px ${rgba(brand.black, 0.08)};
  background: ${brand.white};
  max-height: 240px;
  overflow-y: auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  opacity: 0;
  pointer-events: none;
  cursor: not-allowed;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${rgba(brand.grey.grey20, 0.5)};
    border-radius: 0;
    cursor: pointer;
  }
`;
const Item = styled.div `
  ${fonts.DaxPro.Regular};
  font-size: 18px;
  padding: 14px 18px;
  cursor: pointer;

  &:hover {
    ${ThemeSwitch({ groundforceColour: brand.primary.base })};
    background-color: var(--theme);
    color: ${brand.white};
  }
`;
export default {
    Wrapper,
    Element,
    Dropdown,
    Item,
};
