import { hsl } from 'polished';
export default {
    /** Hex: #1f1f1f */
    black: '#1f1f1f',
    /** Hex: #fff */
    white: '#fff',
    primary: {
        /** Hex: #859a3e */
        base: '#859a3e',
        /** Hex: #558000 */
        dark: '#558000',
        /** Hex: #84bb26 */
        light: '#84bb26',
        /** Hex: #669900 */
        alt: '#669900',
    },
    accent: {
        /** Hex: #FF6600 */
        orange: '#FF6600',
    },
    divisionTags: {
        mrCropper: '#CC0000',
        sandhurst: '#FF6600',
        default: '#669900',
    },
    grey: {
        /** Hex: #E5E5E5 */
        grey89: hsl(0, 0, 0.89),
        /** Hex: #F6F6F6 */
        grey96: hsl(0, 0, 0.96),
        /** Hex: #C1C1C1 */
        grey76: hsl(0, 0, 0.76),
        grey75: hsl(0, 0, 0.75),
        /** Hex: #8D8C8C*/
        grey55: hsl(0, 0, 0.55),
        /** Hex: #707070*/
        grey44: hsl(0, 0, 0.44),
        /** Hex: #666666*/
        grey40: hsl(0, 0, 0.4),
        /** Hex: #5A5A5A */
        grey35: hsl(0, 0, 0.35),
        /** Hex: #333333*/
        grey20: hsl(0, 0, 0.2),
    },
    red: { base: 'red' },
    ysp: {
        input: '#DBE6C7',
        water: '#0097a9',
        ground: '#ab0216',
        excavation: '#93545c',
    },
    tpa: {
        base: '#26358c',
        light: '#009dff',
    },
};
